import styled from 'styled-components';

import { SkeletonInput } from '~/atomic/atom/Skeleton';
import { lessThan } from '~/lib/mediaQuery';

export const CSSSkeleton = styled(SkeletonInput)`
  width: 100% !important;
  height: 100% !important;
  min-height: 280px !important;
  overflow: hidden;
  
  ${lessThan('sm')} {
    min-height: 228px !important;
  }
  
  ${lessThan('xs')} {
    min-height: 170px !important;
  }
`;
