import { unwrapResult } from '@reduxjs/toolkit';
import React, {
  FC, useEffect, useState,
} from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { EditFavoriteGenreModal } from '~/atomic/page/index/FavoriteGenresCheckbox/EditFavoriteGenreModal';
import { FavoriteGenresCheckboxItem } from '~/atomic/page/index/FavoriteGenresCheckbox/FavoriteGenresCheckboxItem';
import { loadFavoriteGenresCheckboxFromStorage, saveFavoriteGenresCheckboxFromStorage } from '~/atomic/page/index/FavoriteGenresCheckbox/FavoriteGenresCheckboxStorage';
import { FeedEroticaBlocked } from '~/atomic/page/index/FavoriteGenresCheckbox/FeedEroticaBlocked';
import {
  getFeed,
  loadDefaultFavoriteGenresInCookie,
  saveFavoriteGenresInCookie,
} from '~/atomic/page/index/feed.data';
import { feedSelector } from '~/atomic/page/index/feed.selector';
import { feedActions } from '~/atomic/page/index/feed.slice';
import { TextRegStyle } from '~/atomic/Typography';
import { environments } from '~/lib/const';
import { lessThan } from '~/lib/mediaQuery';
import { WithCss } from '~/lib/WithCss';
import { useAppDispatch, useAppSelector } from '~/store';

interface FavoriteGenresCheckboxProps {
  listCss?: FlattenSimpleInterpolation;
  className?: string;
}

export const FavoriteGenresCheckbox: FC<FavoriteGenresCheckboxProps> = ({ listCss, ...props }) => {
  const dispatch = useAppDispatch();
  const {
    genres, isUpdateGenres, isShowEroticGenreBLock,
  } = useAppSelector(feedSelector);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isShowAllGenres, setIsShowAllGenres] = useState(true);

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const isCurrentPage = window.location.pathname === pagesConfigStore.home.url;

      if (!isFirstLoad && isCurrentPage && isUpdateGenres) {
        dispatch(feedActions.changeLoadMorePage(1));
        dispatch(getFeed());
        dispatch(saveFavoriteGenresInCookie(undefined));
      }
    }, 1);
  }, [genres, isUpdateGenres]);

  useEffect(() => {
    dispatch(loadDefaultFavoriteGenresInCookie());
  }, []);

  const loadIsShowAllGenres = async () => {
    const result = unwrapResult(await dispatch(loadFavoriteGenresCheckboxFromStorage()));
    if (result && 'isShowAllGenres' in result) {
      setIsShowAllGenres(result.isShowAllGenres || isShowEroticGenreBLock);
    } else {
      setIsShowAllGenres(isShowEroticGenreBLock);
    }
  };

  useEffect(() => {
    if (environments.isClient) {
      loadIsShowAllGenres();
    }
  }, [genres]);

  const toggleShowGenres = () => {
    setIsShowAllGenres((isShow) => !isShow);
    dispatch(saveFavoriteGenresCheckboxFromStorage({ isShowAllGenres: !isShowAllGenres }));
  };

  return (
    <>
      <SCFavoriteGenresCheckbox {...props}>
        <SCTitle
          data-is-show-all-genres={isShowAllGenres}
          onClick={toggleShowGenres}
        >
          Любимые жанры
          <SCArrowIcon
            data-is-show-all-genres={isShowAllGenres}
          />
        </SCTitle>
        <SCList
          data-is-show-all-genres={isShowAllGenres}
          css={listCss}
        >
          {genres?.map((genre) => {
            if (genre.slug === 'erotika' && isShowEroticGenreBLock) {
              return (
                <FeedEroticaBlocked key={genre.name} genre={genre}>
                  <FavoriteGenresCheckboxItem as="div" genre={genre} />
                </FeedEroticaBlocked>
              );
            }

            return (
              <FavoriteGenresCheckboxItem key={genre.name} genre={genre} />
            );
          })}
        </SCList>
      </SCFavoriteGenresCheckbox>
      <EditFavoriteGenreModal />
    </>
  );
};

const SCFavoriteGenresCheckbox = styled.div`
  ${PaperStyle};
  padding: 16px;
  margin-bottom: 20px;
  position: relative;
  z-index: 11;

  ${lessThan('sm')} {
    margin-bottom: 12px;
    padding: 16px 8px;
  }
`;

const SCTitle = styled.span`
  display: flex;
  align-items: center;
  ${TextRegStyle};
  color: var(--gray-color);
  padding-bottom: 12px;
  transition: all 0.3s ease;
  cursor: pointer;

  &[data-is-show-all-genres="false"] {
    padding-bottom: 0;
  }
`;

const SCList = styled.ul<WithCss>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &[data-is-show-all-genres="false"] {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
  }

  ${lessThan('sm')} {
    &[data-is-show-all-genres="false"] {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }

  > * {
    max-width: 25%;
    width: 100%;

    &:nth-child(4n) {
      max-width: 100px;

      ${lessThan('sm')} {
        max-width: 50%;
      }
    }

    ${lessThan('sm')} {
      max-width: 50%;

      &:nth-child(2n) {
        justify-content: flex-end;
      }
    }
  }
  
  ${(props) => props.css}
`;

const SCArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  margin-left: 8px;
  color: var(--gray-color);
  transition: all 0.3s ease;
  position: relative;
  transform: rotate(270deg);

  &[data-is-show-all-genres="false"] {
    transform: rotate(90deg);
  }
`;
