import React from 'react';
import styled from 'styled-components';

import { FeedGenreType } from '~/api/feed/feedApiTypes';
import { Checkbox } from '~/atomic/molecula/Checkbox';
import Modal from '~/atomic/molecula/Modal';
import { changeFeedChildGenres, changeFeedMainGenres } from '~/atomic/page/index/feed.data';
import { feedActiveGenresCountSelector, feedSelector } from '~/atomic/page/index/feed.selector';
import { feedActions } from '~/atomic/page/index/feed.slice';
import { useAppDispatch, useAppSelector } from '~/store';

export const EditFavoriteGenreModal = () => {
  const dispatch = useAppDispatch();
  const { editGenre, isOpenEditGenreModal } = useAppSelector(feedSelector);
  const activeGenresCount = useAppSelector(feedActiveGenresCountSelector);

  const closeModal = () => {
    dispatch(feedActions.setIsUpdateGenres(true));
    dispatch(feedActions.setIsEditGenreModal(false));
  };

  const changeCheckbox = async (targetGenre: FeedGenreType) => {
    const { Modal } = await import('antd');

    const isCanChangeGenre = !(activeGenresCount === 2 && targetGenre.isActive === true);
    if (isCanChangeGenre) {
      dispatch(changeFeedMainGenres(targetGenre));
    } else {
      Modal.warning({
        title: 'Выберите 2 или более основных жанров',
        okText: 'Хорошо',
        maskClosable: true,
        centered: true,
      });
    }
  };

  const changeChildCheckbox = (parentGenre:FeedGenreType, targetGenre: FeedGenreType) => {
    dispatch(feedActions.setIsUpdateGenres(false));
    dispatch(changeFeedChildGenres({
      targetGenre,
      parentGenre,
    }));
  };

  return (
    <SCModal
      onCancel={closeModal}
      open={isOpenEditGenreModal}
    >
      <SCModalChildGenreList
        data-is-all-subgenres-inactive={editGenre.isAllSubgenresInactive}
        data-is-all-subgenres-active={editGenre.isAllSubgenresActive}
      >
        <SCTitleCheckbox>
          <Checkbox
            title={editGenre?.name}
            checked={editGenre?.isActive}
            onClick={() => {
              changeCheckbox(editGenre);
            }}
            className="main-genre"
          />
        </SCTitleCheckbox>
        <SCChildren>
          {editGenre?.childs?.map((childGenre) => (
            <Checkbox
              key={childGenre.name}
              title={childGenre?.name}
              checked={childGenre?.isActive}
              onClick={() => {
                changeChildCheckbox(editGenre, childGenre);
              }}
            />
          ))}
        </SCChildren>
      </SCModalChildGenreList>
    </SCModal>
  );
};

const SCModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

const SCModalChildGenreList = styled.div`
  .main-genre {
    margin-bottom: 10px;
    font-weight: 600;
  }

  &[data-is-all-subgenres-active="false"]&[data-is-all-subgenres-inactive="false"] {
    .main-genre .ant-checkbox-inner {
      background: var(--white-color);
      border: 1px solid #d9d9d9;

      &:after {
        border: 2px solid var(--primary-color);
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(1) translate(-50%,-50%);
        opacity: 1;
      }
    }
  }
`;

const SCTitleCheckbox = styled.div`
  padding: 12px 20px 0;
`;

const SCChildren = styled.div`
  background-color: #F0F0F0;
  padding: 12px 20px 20px;
  
  > *:not(:last-child) {
    padding-bottom: 8px;
  }
`;
