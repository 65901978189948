import { createAsyncThunk } from '@reduxjs/toolkit';

const favoriteGenresCheckboxName = 'favoriteGenresCheckbox';

export const loadFavoriteGenresCheckboxFromStorage = createAsyncThunk<
{ isShowAllGenres: boolean } | undefined
>(
  'loadFavoriteGenresCheckboxFromStorage',
  async () => {
    if (window?.localStorage) {
      const result = JSON.parse(localStorage.getItem(favoriteGenresCheckboxName));

      return result;
    }
  },
);

export const saveFavoriteGenresCheckboxFromStorage = createAsyncThunk<
void,
{ isShowAllGenres: boolean }
>(
  'saveFavoriteGenresCheckboxFromStorage',
  async (data) => {
    if (window?.localStorage) {
      localStorage.setItem(favoriteGenresCheckboxName, JSON.stringify(data));
    }
  },
);
