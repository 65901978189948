import React, { FC } from 'react';
import styled from 'styled-components';

import { FeedGenreType } from '~/api/feed/feedApiTypes';
import { Checkbox } from '~/atomic/molecula/Checkbox';
import { changeFeedMainGenres } from '~/atomic/page/index/feed.data';
import { feedActiveGenresCountSelector, feedSelector } from '~/atomic/page/index/feed.selector';
import { feedActions } from '~/atomic/page/index/feed.slice';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

interface FavoriteGenresCheckboxItemProps {
  genre: FeedGenreType;
  as?: React.ElementType<any>;
}

export const FavoriteGenresCheckboxItem: FC<FavoriteGenresCheckboxItemProps> = ({ genre, as }) => {
  const dispatch = useAppDispatch();
  const activeGenresCount = useAppSelector(feedActiveGenresCountSelector);
  const { isShowEroticGenreBLock } = useAppSelector(feedSelector);
  const isErotica = genre.slug === 'erotika' && isShowEroticGenreBLock;

  const changeCheckbox = async (targetGenre: FeedGenreType) => {
    const { Modal } = await import('antd');

    const isCanChangeGenre = !(activeGenresCount === 2 && targetGenre.isActive === true);
    if (isCanChangeGenre) {
      dispatch(changeFeedMainGenres(targetGenre));
    } else {
      Modal.warning({
        title: 'Выберите 2 или более основных жанров',
        okText: 'Хорошо',
        maskClosable: true,
        centered: true,
      });
    }
  };

  const openSubgenreModal = () => {
    dispatch(feedActions.setIsEditGenreModal(true));
    dispatch(feedActions.changeEditGenre(genre));
  };

  return (
    <SCItemWrapper
      key={genre.name}
      as={as}
    >
      <SCItem
        data-is-all-subgenres-inactive={genre.isAllSubgenresInactive}
        data-is-all-subgenres-active={genre.isAllSubgenresActive}
      >
        {genre.childs.length ? (
          <Checkbox
            title={genre.name}
            checked={genre.isActive}
            className="main-genre"
            disabled={isErotica}
            onClick={(event) => {
              event.preventDefault();
              openSubgenreModal();
            }}
          />
        ) : (
          <Checkbox
            title={genre.name}
            checked={genre.isActive}
            onClick={() => {
              changeCheckbox(genre);
            }}
          />
        )}
      </SCItem>
    </SCItemWrapper>
  );
};

const SCItemWrapper = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 4px;
  margin-bottom: 4px;
  transition: all 0.3s ease;
  position: relative;
  
  > * {
    z-index: 1;
    min-width: 150px;
  }
  
  .ant-checkbox {
    transform: translate(0, 0);
    align-self: center;
  }

  ${lessThan('sm')} {
    .ant-checkbox {
      margin-right: 6px;
    }
  }
`;

const SCItem = styled.div`
  position: relative;

  &[data-is-all-subgenres-active="false"]&[data-is-all-subgenres-inactive="false"] {
      .main-genre .ant-checkbox-inner {
        background: var(--white-color);
        border: 1px solid #d9d9d9;
        
        &:after {
          border: 2px solid var(--primary-color);
          border-top: 0;
          border-left: 0;
          transform: rotate(45deg) scale(1) translate(-50%,-50%);
          opacity: 1;
        }
      }
  }
`;
