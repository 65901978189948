import React, { FC } from 'react';
import styled from 'styled-components';

import { FeedGenreType } from '~/api/feed/feedApiTypes';
import { Button } from '~/atomic/atom/Button';
import { changeFeedMainGenres, saveFavoriteGenresInCookie } from '~/atomic/page/index/feed.data';
import { feedActions } from '~/atomic/page/index/feed.slice';
import { TextRegStyle } from '~/atomic/Typography';
import { lessThan } from '~/lib/mediaQuery';
import { WithChildren } from '~/lib/WithChildren';
import { useAppDispatch } from '~/store';

interface FeedEroticaBlockedProps extends WithChildren {
  genre: FeedGenreType;
}

export const FeedEroticaBlocked: FC<FeedEroticaBlockedProps> = ({
  genre,
  children,
}) => {
  const dispatch = useAppDispatch();

  const onClickShowEroticHandler = () => {
    dispatch(feedActions.setIsShowEroticGenreBLock(false));
    dispatch(changeFeedMainGenres(genre));
  };

  const onClickHideEroticHandler = () => {
    dispatch(feedActions.setIsShowEroticGenreBLock(false));
    dispatch(saveFavoriteGenresInCookie(undefined));
  };

  return (
    <SCFeedEroticaBlocked>
      {children}
      <SCPopover>
        <SCImage src="/icon/components/FeedEroticaBlocked/erotica.png" alt="" />
        <SCMain>
          <SCText>
            Вы согласны видеть книги из жанра Эротика?
          </SCText>
          <SCButtons>
            <Button
              type="primary"
              onClick={onClickShowEroticHandler}
            >
              Да
            </Button>
            <SCButton
              onClick={onClickHideEroticHandler}
            >
              Нет
            </SCButton>
          </SCButtons>
        </SCMain>
      </SCPopover>
    </SCFeedEroticaBlocked>
  );
};

const SCPopover = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  z-index: 2;
  top: 45px;
  right: 50%;
  transform: translateX(50%);
  width: 270px;
  padding: 16px;
  background-color: var(--black-color);
  
  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    top: -5px;
    right: 50%;
    position: absolute;
    background-color: var(--black-color);
    transform: translateX(50%) rotate(45deg);
  }
  
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
    top: -20px;
    right: 50%;
    position: absolute;
    transform: translateX(50%);
  }
  
  ${lessThan('lg')} {
    left: -140px;
    transform: translateX(0);

    &:after {
      right: 30%;
      transform: rotate(45deg);
    }
  }
`;

const SCFeedEroticaBlocked = styled.li`
  position: relative;
`;

const SCText = styled.span`
  ${TextRegStyle};
  color: var(--white-color);
`;

const SCImage = styled.img`
  margin-right: 8px;
`;

const SCMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SCButtons = styled.div`
  display: flex;
  margin-top: 12px;
  
  > *:not(:last-child) {
    margin-right: 18px;
  }
`;

const SCButton = styled(Button)` && {
    color: var(--white-color);
  }
`;
