import styled from 'styled-components';

import { breakepoints } from '~/atomic/breakepoints';
import { TextSmallStyle } from '~/atomic/Typography';
import { lessThan } from '~/lib/mediaQuery';

export const SCPaginationWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`;

export const SCCatalog = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${breakepoints['container-width-down']}) {
    padding-left: 16px;
    padding-right: 16px;
  }

  ${lessThan('sm')} {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const SCCatalogList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 30px;
  width: 100%;
  
  ${lessThan('md')} {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
`;

export const SCCatalogGrid = styled.div`
  --column-gap: 24px;
  --row-gap: 28px;
  --items-count: 6;
  --column-gap-count: calc(var(--items-count) - 1);
  --column-gaps-width: calc(var(--column-gap) * var(--column-gap-count));
  --free-space-for-items: calc(100% - calc(var(--column-gaps-width)));
  --item-width: calc(var(--free-space-for-items) / var(--items-count));

  display: grid;
  justify-content: flex-start;
  grid-template-columns: repeat(var(--items-count), var(--item-width));
  grid-gap: var(--row-gap) var(--column-gap);
  padding: 2px;
  width: 100%;

  ${lessThan('md')} {
    --items-count: 4;
    --column-gap: 14px;
    --row-gap: 18px;
  }

  ${lessThan('sm')} {
    --items-count: 3;
    --column-gap: 6px;
    --row-gap: 8px;
  }
`;

export const SCSearchWord = styled.h1`
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 21px;
  margin-right: 16px;

  color: var(--black-color);
`;

export const SCBreadCrumb = styled.div`
  ${TextSmallStyle};
  margin-bottom: 16px;
  margin-left: 0;
  margin-right: auto;

  ${lessThan('sm')} {
    display: none;
  }
`;

export const SCLink = styled.a`
  color: var(--gray-color);
`;
